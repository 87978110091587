/* eslint-disable */
import DEFAULT_PROP from '@/assets/json/field_types.json'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['getHeadFootDocuments', 'userDetails']),
    propertiesFormReference () {
      this.formProps.forEach(element => {
        element.class = this.isCreate ? 'lg12' : 'xs12'
      })
      let title = this.isShowAttachment ? this.$t('upload') : this.$t('updateMetaData')
      let label = this.isCreate ? 'Create' : this.$t('upload')
      return {
        title: this.isConvertPdf ? this.$t('create') : this.isCreate ? '' : title,
        hideCard: this.isConvertPdf ? false : this.isCreate,
        properties: this.formProps,
        buttons: [{
          name: 'action_handler',
          color: 'success',
          label: this.isConvertPdf ? this.$t('create') : this.isShowAttachment ? label : this.$t('update'),
          click: this.isConvertPdf ? this.convertToPdf : this.isShowAttachment ? this.uploadDocumentHandler : this.updateDocumentMeta,
          loading: this.documentFormloading,
          is_show: true
        }, {
          name: 'close',
          color: 'error',
          click: this.closeDocumentDialogHandler,
          label: this.$t('close'),
          is_show: true
        }]
      }
    },
    folderActions () {
      return [
        ...(this.userDetails.issharepointuser ? [
          {
            tooltip: this.$t('openNewTab'),
            icon: 'mdi-open-in-new',
            color: 'primary',
            click: (document) => this.navigateToOD(document)
          }
        ] : []),
        ...(this.recordObj.accesscontrol.edit ? [
          {
            tooltip: this.$t('rename'),
            icon: 'mdi-border-color',
            color: 'success',
            click: (document) => this.renameFolderFormShow(document)
          }, {
            tooltip: this.$t('route'),
            icon: 'mdi-text-box-check-outline',
            color: 'teal',
            click: (document) => this.openRouteDialog(document)
          }
        ] : [])
      ]
    },
    documentsplActions () {
      return [{
        tooltip: this.$t('quotePreview'),
        icon: 'mdi-eye',
        color: 'primary',
        click: (document) => this.previewHandler(document)
      }]
    },
    docusignActions () {
      return [{
        tooltip: this.$t('signDocument'),
        icon: 'mdi-signature',
        color: 'info',
        click: (document) => this.signatureHandler(document)
      }]
    },
    virtualDocActions () {
      return [
        ...(this.userDetails.issharepointuser ? [
          {
            tooltip: this.$t('openNewTab'),
            icon: 'mdi-open-in-new',
            color: 'primary',
            click: (document) => this.navigateHandler(document)
          }] : []
        ),
        ...([
          {
            tooltip: this.$t('download'),
            icon: 'mdi-cloud-download',
            color: 'accent',
            click: (document) => this.downloadHandler(document)
          }, {
            tooltip: this.$t('route'),
            icon: 'mdi-text-box-check-outline',
            color: 'teal',
            click: (document) => this.getWorkflowDetails(document)
          }
        ])
      ]
    },
    documentActions () {
      return [
        ...(this.userDetails.issharepointuser ? [
          {
            tooltip: this.$t('openNewTab'),
            icon: 'mdi-open-in-new',
            color: 'primary',
            click: (document) => this.navigateHandler(document)
          }
        ] : []),
        ...([
          {
            tooltip: this.$t('download'),
            icon: 'mdi-cloud-download',
            color: 'accent',
            click: (document) => this.downloadHandler(document)
          }, {
            tooltip: this.$t('versionHistory'),
            icon: 'mdi-history',
            color: 'purple',
            click: (document) => this.getVersionInfo(document.id)
          }
        ]),
        ...((this.accesscontrol && this.accesscontrol.edit) || this.recordObj.accesscontrol.edit ? [
          {
            tooltip: this.$t('editMeta'),
            icon: 'mdi-border-color',
            color: 'success',
            click: (document) => this.editMetaInDocument(document)
          }, {
            tooltip: this.$t('route'),
            icon: 'mdi-text-box-check-outline',
            color: 'teal',
            click: (document) => this.openRouteDialog(document)
          }, {
            tooltip: this.$t('delete'),
            icon: 'mdi-delete',
            color: 'error',
            click: (document) => this.deleteHandler(document.id)
          }
        ] : [])
      ]
    }
  },
  created () {
    // this.$store.dispatch('getHeaderFooterDocuments')
  },
  methods: {
    prepareFieldsForProperties () {
      const dynamicInputs = []
      this.dateFieldsForConversion = []
      for (let i = 0; i < this.dynamicFormFields.length; i++) {
        const field = this.dynamicFormFields[i]
        if (!field.readonly) {
          switch (field.type) {
            case DEFAULT_PROP.URL:
            case DEFAULT_PROP.TEXT:
            case DEFAULT_PROP.FILE:
            case DEFAULT_PROP.CURRENCY:
              dynamicInputs.push({ model: field.name, type: this.formType.TEXT, label: field.title, class: 'xs12', rules: field.required ? this.$_requiredValidation : [] })
              break
            case DEFAULT_PROP.NUMBER:
            case DEFAULT_PROP.INTERGER:
              dynamicInputs.push({ model: field.name, type: this.formType.NUMBER, label: field.title, class: 'xs12', rules: field.required ? this.$_requiredValidation : [] })
              break
            case DEFAULT_PROP.NOTE:
              dynamicInputs.push({ model: field.name, type: this.formType.TEXTAREA, label: field.title, class: 'xs12', rules: field.required ? this.$_requiredValidation : [] })
              break
            case DEFAULT_PROP.BOOLEAN:
              dynamicInputs.push({ model: field.name, type: this.formType.CHECKBOX, label: field.title, class: 'xs12', rules: field.required ? this.$_requiredValidation : [] })
              break
            case DEFAULT_PROP.CHOICE:
              dynamicInputs.push({ model: field.name, type: this.formType.AUTO_COMPLETE, items: field.choices, label: field.title, class: 'xs12', rules: field.required ? this.$_requiredValidation : [] })
              break
            case DEFAULT_PROP.MULTICHOICE:
              dynamicInputs.push({ model: field.name, type: this.formType.AUTO_COMPLETE, items: field.choices, multiple: true, label: field.title, class: 'xs12', rules: field.required ? this.$_requiredValidation : [] })
              break
            case DEFAULT_PROP.USER:
              dynamicInputs.push({ model: field.name, type: this.formType.AUTO_COMPLETE, items: field.users, select_value: 'id', select_text: 'name', label: field.title, class: 'xs12', rules: field.required ? this.$_requiredValidation : [] })
              break
            case DEFAULT_PROP.DATETIME:
              this.dateFieldsForConversion.push(field.name)
              dynamicInputs.push({ model: field.name, type: this.formType.DATEPICKER, menu: `${field.name}_menu`, picker: `${field.name}_picker`, label: field.title, class: 'xs12', color: 'primary lighten-1', rules: field.required ? this.$_requiredValidation : [] })
              this.$watch(`propertyModel.properties.${field.name}_picker`, (val) => {
                if (val) {
                  this.propertyModel.properties[field.name] = this.$formatter.formatDate(val, 'YYYY-MM-DD', this.userDetails.dateformat)
                }
              })
              break
            default:
              break
          }
        }
      }
      this.formProps = dynamicInputs
    },
    previewHandler (document) {
      this.loading = true
      this.$api.execute('get', `documents/${this.moduleObj.moduleName}/get_msg_file/${document.id}`).then(response => {
        response.data.to = this.groupMultipleObjectEmailValue(response.data.to)
        response.data.cc = this.groupMultipleObjectEmailValue(response.data.cc)
        response.data.bcc = this.groupMultipleObjectEmailValue(response.data.bcc)
        response.data.attachments = response.data.attachments.map(x => x).join(', ')
        response.data.date_on = this.$formatter.formatDate(response.data.date_on, 'DD.MM.YYYYTHH.mm.ss', 'dddd, MMMM Do YYYY, h:mm:ss a')
        this.previewContent = response.data
      }).finally(() => {
        this.previewDialog = true
        this.loading = false
      })
    },
    signatureHandler (document) {
      this.loading = true
      this.payload.loading = true
      window.localStorage.setItem(process.env.VUE_APP_REDIRECT_URL, this.$route.path)
      this.$api.execute('get', `docusign/get_envelope/${document.id}`).then(response => {
        this.loading = false
        this.payload.loading = false
        if (response.data.code === 401) window.open(response.data.url, '_self')
        else {
          if (response.data.signed_already) {
            this.envelopeObj = response.data
            this.envelopeDialog = true
          } else {
            document.subject = `Sign: ${document.name}`
            this.signatureObj = { ...document, choiceMade: null }
            this.showSignatureDialog = true
          }
        }
      })
    },
    documentDialog (type) {
      this.propertyModel = { properties: {}, templateProperties: {} }
      if (type === 'create') {
        this.isCreate = true
        this.propertyModel.type = type
      } else {
        this.isCreate = false
        this.uploadDocumentDialog = true
      }
      this.isShowAttachment = true
      const attachmentRefIndex = this.formProps.findIndex(x => x.model === 'files')
      if (attachmentRefIndex !== -1) this.formProps.splice(attachmentRefIndex, 1)
      if (this.isShowAttachment) {
        if (!this.isCreate) {
          this.formProps.push({
            model: 'files',
            type: this.formType.FILES,
            is_show: this.isShowAttachment && !this.isCreate,
            rules: this.$_requiredValidation,
            label: this.$t('files'),
            hideDetails: true,
            class: 'lg12'
          })
        }
      }
      if (this.$refs && this.$refs.documentReference) this.$refs.documentReference.$refs.validateForm.reset()
    },
     documentDialogConvertPDf (type, documentObj) {
      this.convertPDFObject = documentObj
      this.propertyModel = { properties: {}, templateProperties: {} }
      this.isCreate = true
      let attachmentRefIndex = this.formProps.findIndex(x => x.model === 'files')
      if (attachmentRefIndex !== -1) this.formProps.splice(attachmentRefIndex, 1)
      let attachmentRefTempIndex = this.formProps.findIndex(x => x.model === 'template_id')
      if (attachmentRefTempIndex !== -1) this.formProps.splice(attachmentRefTempIndex, 1)
      this.propertyModel.type = type
      this.uploadDocumentDialog = true
      this.isConvertPdf = true
      this.isShowAttachment = false
      if (this.$refs && this.$refs.documentReference) this.$refs.documentReference.$refs.validateForm.reset()
    },
    editMetaInDocument (item) {
      this.isShowAttachment = false
      this.isCreate = false
      this.uploadDocumentDialog = true
      this.propertyModel = this.$formatter.cloneVariable(item)
      const properties = this.propertyModel.properties
      const formModel = {}
      for (let i = 0; i < properties.length; i++) {
        this.dateFieldsForConversion.forEach(element => {
          if (element === properties[i].name && properties[i].value) {
            properties[i].value = this.$format.formatDate(properties[i].value, 'DD.MM.YYYYTHH:mm:ss', this.userDetails.dateformat)
          }
        })
        if (properties[i].value && properties[i].value.lookupid) formModel[properties[i].name] = properties[i].value.lookupid
        else formModel[properties[i].name] = properties[i].value
      }
      this.propertyModel.properties = formModel
      const templateRefIndex = this.formProps.findIndex(x => x.model === 'template_id')
      if (templateRefIndex >= 0) this.formProps.splice(templateRefIndex, 1)
      const attachmentRefIndex = this.formProps.findIndex(x => x.model === 'files')
      if (attachmentRefIndex >= 0) this.formProps.splice(attachmentRefIndex, 1)
    },
    closeDocumentDialogHandler () {
      this.uploadDocumentDialog = false
      this.isCreate = false
      this.isShowAttachment = false
      this.convertPDFObject = null
      this.isConvertPdf = false
      //this.$refs.documentReference.$refs.validateForm.reset()
       if (this.$refs && this.$refs.documentReference) this.$refs.documentReference.$refs.validateForm.reset()
     },
    navigateToOD (document) {
      window.open(document.path, '_blank')
    },
    navigateHandler (document) {
      if (document.weburl) window.open(document.weburl, '_blank')
      else window.open(document.path, '_blank')
    },
    downloadHandler (document) {
      this.loading = true
      window.open(document.downloadurl, '_blank')
      this.loading = false
    },
    getVersionInfo (id) {
      this.loading = true
      this.$api.execute('get', `sharepoint/get_document_versions/${id}`)
        .then(({ data }) => {
          this.versionHistory = []
          if (data && data.length) {
            data.forEach((item) => {
              item.modified_on = (item.modified_on) ? this.$formatter.formatDate(item.modified_on, '', `${this.userDetails.dateformat} HH:mm`) : null
            })
            this.versionHistory = data
          }
          this.versionHistoryDialog = true
        }).finally(() => {
          this.loading = false
        })
    },
    renameFolderFormShow (item) {
      this.isCreateFolder = false
      this.renameModel.id = item.id
      this.renameModel.name = item.name
      this.folderRenameDialog = true
    },
    deleteHandler (id) {
      this.$store.commit('showDeleteDialog', {
        url: `sharepoint/delete/${id}`,
        module: 'sharepoint',
        isSingle: true,
        moduleid: this.moduleObj.moduleId,
        recordid: this.moduleObj.recordId
      })
    },
    convertToPdf () {
      const document = this.convertPDFObject
      let modelProps = this.$formatter.cloneVariable(this.propertyModel.properties)
      let finalModel = {}
      this.dynamicFormFields.forEach(ele => {
        if (!ele.readonly && modelProps.hasOwnProperty(ele.name)) {
          finalModel[ele.name] = modelProps[ele.name]
        }
      })
      const model = {
        documentId: document.id,
        parentsharepointid: this.activeFolder.id,
        parentrecordid: this.moduleObj.recordId,
        properties: modelProps
      }
      this.documentFormloading = true
      this.$api.execute('post', `documents/${this.moduleObj.moduleName}/convert_to_pdf/${document.id}/${this.moduleObj.recordId}`, model).then(response => {
        if (response) {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'convertSuccess' })
          this.getListOfDocuments()
        }
      }).finally(() => {
        this.documentFormloading = false
        this.uploadDocumentDialog = false
        this.convertPDFObject = null
        this.isConvertPdf = false
      })
    },
    getWorkflowDetails (document) {
      this.$api.execute('get', `documentworkflows/${document.workflowid}`).then(response => {
        this.$eventBus.$emit('openWorkflowDialog', { ...response.data, document: document })
      })
    },
    openRouteDialog (document) {
      this.$eventBus.$emit('openRouteDialog', document)
    }
  }
}
